.gray-rectangle{
  position: relative;
  background: rgba(235, 235, 235, 0.7);
}

.contact-us{
     width: 100vw;
     height: 550px;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     background: rgba(245, 235, 235, 0.3);
     background-image: url('./images/contact-us.jpg');
     background-blend-mode: overlay;
     background-size: cover;
     background-position: center;
     background-repeat: no-repeat;
}

.form-control{
    background-color: #ffffffca;
}

.email-line{
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  margin-block: 1rem;
  text-align: center;
  color: #3A2975;
}

.contact-us .title {
  font-weight: 400;
  font-size: 2.5rem;
  text-align: center;
  color: #760326;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
}

.blur-effect {
  filter: blur(1px); /* Adjust the blur value as per your preference */
}

.contact-ty-text {
  margin-top: 1rem;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  color: #760326;
}

.contact-ty-text-2 {
  margin-top: 1rem;
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  color: #760326;
}

.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 600px) {

    iframe{
        width: 420px;
        height: 100%;
    }
    /* .contact-us{
        height: 25rem;
    } */
    .form{
        margin: 0px 25px 0px 25px;
    }

    .email-line{
      font-family: 'Roboto Serif';
      font-style: normal;
      font-weight: 500;
      font-size: 19px;
      line-height: 30px;
      margin-left: 15px;
      /* or 176% */
    
      display: flex;
      align-items: center;
    
      color: #3A2975;
    }
    /* .contact-us button{
        width: 11rem;
        height: 2.25rem;
    } */
}