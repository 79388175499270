.latest-news-header-bg{
  background: #230E15A8;
}

.latest-news-header{
    height: 200px;
    background:linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('./images/latest-news-bg.png');
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background-repeat: no-repeat;
    opacity: 66%;
    background-size: cover;
}

.latest-news-heading{
    font-size: 2.5rem;
    width: 100%;
    text-align: center;
    color: #DBC7C3;
    margin: auto;
}

.latest-news-linkdin{
  margin: 20px 20px;
}