.company-profile-content{
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: auto;
}

.company-profile .image{
  display: flex;
  flex-direction: column;
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('./images/mainBg.png');
  align-items: center;
  justify-content: center;
  height: 350px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Roboto', sans-serif;
  font-size: 60px;
  font-weight: 400;
  line-height: 80px;
  letter-spacing: 0em;
  text-align: center;
  color: #DBC7C3;
}

.company-profile-header-image{
  height: 150px;
  background: #2C000D;
}

.company-profile-main-heading{
  font-family: 'Roboto Serif';
  font-size: 60px;
  font-weight: 500;
  line-height: 50px;
  letter-spacing: -0.03em;
  text-align: center;
  color: #DBC7C3;
  margin-top: 45px;
}

.company-profile-header-underline{
  margin: 0 auto;
  border: 3px solid #DBC7C3;
  width: 27vw;
}

.company-profile-header-section{
  width: 100%;
  margin: auto;
}

.company-profile-header-section-pdf{
  display: flex;
  flex-direction: column;
  margin: 35px;
}

.company-header-section1{
  display: flex;
  flex-direction: column;
  margin: 1rem;
}

.back-arrow-icon{
  width: 1.5rem;
  height: 1.5rem;
  margin-block: auto;
  cursor: pointer;
}

.company-title{
  font-family: 'Roboto', sans-serif;
  font-size: 3rem;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  color: #760326;
}

@media (max-width: 800px) {
  .company-title{
    font-size: 2rem;
  }
}

.company-certification{
  font-family: 'Roboto', sans-serif;
  font-size: 33px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  color: #760326;
}

.verified-icon{
  width: 63px;
  height: 68px;
  margin-left: 30px;
}

.company-header-section2{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem;
}

.company-header-subsection1-2{
  font-size: 1rem;
  font-weight: 700;
  line-height: 2.5rem;
  letter-spacing: 0px;
  text-align: left;
  color: #760326;
}

@media (max-width: 800px) {
  .company-header-subsection1-2{
    font-size: 0.8rem;
    line-height: 1.2rem;
  }
}

.linespacing{
  background: white;
  height: 20px;
}

.company-header-subsection2-2{
  display: flex;
  flex-direction: column;
}

.company-header-subsubsection1-2-2{
  display: flex;
}

.reporting-year{
  font-size: 19px;
  font-weight: 700;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
  color: #5F5F61;
  margin-left: 15px;
}

.company-header-subsubsection2-2-2{
  display: flex;
  margin-top: 10px;
}

.year-select{
  color: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  border: 1px solid #0000001A;
  height: 36px;
  width: 71px;
  margin-left: 25px;
  border-radius: 8px;
  text-align: center;
  display: flex;
}

.red-button{
  border-radius: 1rem;
  padding: 0.2rem 1rem;
  background: #760326;
  cursor: pointer;
}

.red-button-text{
  font-size: 1rem;
  font-weight: 600;
  margin: auto;
  text-align: center;
  color: white;
}

.profile-line{
  border: 1px solid #D0C8C8;
  background: #D0C8C8;
  margin: 1rem 0;
}

.company-header-section3{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 1rem;
}

.info-titles{
  font-size: 1rem;
  font-weight: 700;
  text-align: left;
  color: #6D6E76;
}

.nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.info-details {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.8rem;
  padding: 0.3rem;
  letter-spacing: 0px;
  text-align: left;
  color: #6D6E76;
}

@media (max-width: 800px) {
  .info-titles {
    font-size: 0.8rem;
    line-height: 2rem;
  }
  .info-details {
    font-size: 0.8rem;
    line-height: 1.5rem;
  }
}

.info-titles-line{
  border: 1px solid white;
}

.info-details-line{
  width: auto;
  border: 1px solid #D0C8C833;
}

.info-certificate{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.media-reports{
  text-align: center;
  font-size: 19px;
  font-style: italic;
  font-weight: 800;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: center;
  color: #5F5F61;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 20px;
}

.expand-all-div{
  display: flex;
  justify-content: center;
  text-align: center;
}

.red-button-thicker{
  padding: 0.2rem 1rem;
  border-radius: 1rem;
  background: #760326;
  margin: 0.5rem;
  cursor: pointer;
}

.red-button-thicker-text{
  font-size: 1rem;
  font-weight: 600;
  margin: auto;
  color: white;
}

.company-policy{
  background: #F4F4F4;
  margin: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.company-policy-pdf{
  background: #F4F4F4;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
}

.company-policy-header{
  display: flex;
  flex-direction: column;
  justify-content: center;
  
}

.company-policy-header-line1{
  display: flex;
  justify-content: space-between;
}

.company-policy-title{
  font-size: 1.3rem;
  margin-block: auto;
  font-weight: 700;
  text-align: left;
  color: #760326;
}

.plus-sign-btn {
  background: #760326;
  width: 2rem;
  height: 2rem;
  border-radius: 5px;
  cursor: pointer;
  margin: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plus-sign-icon {
  width: 1rem;
  height: 1rem;
  color: white;
}

.company-policy-text{
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: left;
  color: #760326;
}

.company-policy-items{
  display: flex;
  margin-block: 0.8rem;
  justify-content: space-between;
  background: linear-gradient(0deg, #979797, #979797), linear-gradient(0deg, #656568, #656568);
  border: 1px solid #656568;
}

.company-policy-items-pdf{
  display: flex;
  margin: 20px;
  justify-content: space-between;
  padding: 15px 30px 25px 30px;
  background: linear-gradient(0deg, #979797, #979797), linear-gradient(0deg, #656568, #656568);
  border: 1px solid #656568;
}

.company-policy-item-text{
  font-size: 1.3rem;
  margin: 0.5rem 1rem;
  font-weight: 700;
  text-align: left;
  color: white;
  cursor: pointer;
}

@media (max-width: 800px) {
  .company-policy-item-text {
    font-size: 1rem;
    margin: 0.3rem 0.5rem;
  }
}

.company-policy-item-sub-text{
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  color: purple;
  margin-inline: 2rem;
  display: none;
  word-break: break-word;
}

@media (max-width: 800px) {
  .company-policy-item-sub-text {
    font-size: 0.8rem;
    margin-inline: 0.5rem;
  }
}

.company-policy-item-ans-section{
  margin-left: 16px;
  font-weight: 600;
  margin-top: 10px;
  display: block;
}

.company-policy-item-ans-line{
  margin-left: 16px;
  font-weight: 600;
  display: block;
}

.company-policy-item-sub-text-pdf{
  font-size: 17px;
  font-weight: 400;
  letter-spacing: 0px;
  text-align: left;
  color: purple;
  margin: auto 40px;
}

.company-policy-item-star{
  font-size: 17px;
  font-style: italic;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-align: center;
  color: #760326;
  margin: auto 40px;
}

.launching-modal-profile {
  z-index: 9999;
  top: 5rem;
  background: #FEF6F7;
  width: 80%;
  left: 10%;
}