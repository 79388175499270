.company-scorecard .image{
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('./images/mainBg.png');
  display: flex;
  align-items: center;
  justify-content: center;
  height: 350px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  font-family: 'Roboto', sans-serif;
  font-size: 48px;
  font-weight: 400;
  line-height: 80px;
  letter-spacing: 0em;
  text-align: center;
  color: #DBC7C3;
}

.company-scorecard-content {
  margin: 20px 30px;

  .cert-header{
    font-size: 19px;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0px;
    text-align: left;
    color: #6D6E76;
  }
  .cert-header-date{
    font-size: 17px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0px;
    text-align: left;
  }
  .cert-line{
    border: 1px solid #D0C8C8;
    background: #D0C8C8;
  }
  .cert-text{
    margin-top: 20px;
    font-size: 17px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0px;
    color: #6D6E76;
  }
  .cert-read-more{
    font-size: 17px;
    font-style: italic;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0px;
    text-align: left;
    color: #760326;
  }
}
.search-filter-download{
  display: flex;
  justify-content: center;
}

.cert-search-div{
  display: flex;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 1px solid #97979780;
  border-radius: 5px;
  margin: 20px 25px;

}
.cert-search{
  height: 48px;
  width: 452px;
  display: flex;
  justify-content: space-between;
  
}
.cert-search-text{
  font-size: 17px;
  font-style: italic;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-align: left;
  color: rgba(133, 129, 129, 0.82);
}
.cert-search-icon{
  height: 28px;
  width: 32px;
  margin: auto 0px;
  margin-right: 20px;
  cursor: pointer;
}

.cert-filter{
  display: flex;
  flex-direction: column;
  width: 53px;
  height: 51px;
  border-radius: 5px;
  background: rgba(118, 3, 38, 1);
  box-shadow: 0px 10px 20px 0px rgba(54, 58, 120, 0.1);
  margin: auto 0px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(255, 255, 255, 0.94);
  cursor: pointer;

  .span1{
    position: absolute;
    margin: -4px 10px;
    height: 6px;
  }
  .span2{
    position: absolute;
    margin: 2px 15px;
    height: 6px;
  }
  .span3{
    position: absolute;
    margin: 8px 20px;
    height: 6px;
  }
}

.cert-download{
  width: 163px;
  height: 46px;
  margin: auto 20px;
  border-radius: 20px;
  background: rgba(118, 3, 38, 1);
  box-shadow: 0px 10px 20px 0px rgba(54, 58, 120, 0.1);
  text-align: center;
  cursor: pointer;

  .span4 {
    font-size: 19px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: rgba(255, 255, 255, 1);
    margin: 8px 38px;
  }
}

.company-details-table-container{
  margin: 20px 60px;
}

.company-details-table{
  color: #7F7F7F;
  width: 100%;
  border: 2px solid #BFBFBF;
  border-collapse: collapse;
  font-size: 0.8rem;

  tr{
    text-align: center;
  }
  thead {
    /* position: sticky;
    top: 0;
      Remove the position: sticky property */
    position: static;
    top: initial;
  }
  th {
    /* position: sticky;
    top: 0;
    Remove the position: sticky property */
    position: static;
    top: initial;
    border: 2px solid #BFBFBF;
    padding: 18px;
  }
  td {
    border: 2px solid #BFBFBF;
    color: #5f124f;
    font-weight: bold;
    padding: 12px 0;
  }
}

.company-details-table-pdf{
  color: #7F7F7F;
  width: 100%;
  table-layout: fixed;
  border: 2px solid #BFBFBF;
  border-collapse: collapse;

  tr{
    text-align: center;
  }
  thead {
    position: static;
    top: initial;
  }
  th {
    position: static;
    top: initial;
    border: 2px solid #BFBFBF;
    padding: 18px;
  }
  td {
    word-break: break-word;
    border: 2px solid #BFBFBF;
    color: #5f124f;
    font-weight: bold;
    padding: 12px 0;
  }
}

.pagination {
  font-size: 17px;
  font-style: italic;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
  color: #656568;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 100%;
  overflow-x: scroll;
}
.active-page{
  color: #760326;
  cursor: pointer;
}
.inactive-page{
  text-decoration: underline;
  cursor: pointer;
}

.green-tick{
  color: #70ad47 !important;
}

.scorecard-modal{
  height: 100vh;
  z-index: 9999;
  /* background: #6D6D6DCC; */
}

.scorecard-submodal{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 150px;
} 

.blur-effect {
  filter: blur(1.2px);
}

.scorecard-popup{
  background: #430115;
  width: 829px;
  height: 138px;
  border-radius: 2px 0px 0px 0px;
}

.scorecard-popup-text{
  font-size: 18px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.03em;
  text-align: left;
  color: white;
  margin: 24px;
}
