.navbar{
    background-color: #fefefe;
    box-shadow: 0px 1px 10px #999;
}
.navbar-brand {
    height: 3rem;
    display: flex;
}

.navbar-brand img {
    align-self: center;
}

.navbar-collapse{
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.dropdown-content a:hover{
  color: #DBC7C3;
}

.tabButton {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}

.nav-item.dropdown:hover .dropdown-menu {
    display: block;
}

.dropdown-menu {
    border-radius: 0;
    border: none;
}

.navbar-title-div{
    white-space: nowrap;
}
.nav-link{
    color: #760326;
    font-size: 1rem;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    border-bottom:2px solid transparent;
}

.nav-link:hover {
    border-bottom:2px solid #760326;
    border-bottom-color: 2px;
    color: #760326;
}

.navbar-nav ul{
    margin-bottom: 0px !important;
    padding-left: 0.4rem !important;
}

.logo {
    height: 2rem;
    object-fit: cover;
}

@media only screen and (max-width: 600px) {
    .navbar-collapse {
        height: 100vh;
        align-items: flex-start;
    }
    button:not(.collapsed) > .navbar-toggler-icon{
        background-image:  url('./images/cross.png');
    }

    .navbar-toggler:not(.collapsed) {
       border: none;
    }
    .navbar-toggler:focus {
        box-shadow: none;
    }
    .navbar-nav{
        width: 100%;
    }

    .nav-link:hover {
        border: none;
    }
    .show>.nav-link{
        color:#3A2975 !important
    }
    .nav-link{
        color: #3A2975;
        margin-right: 20px;
        font-size: 20px;
    }
    .dropdown-item {
        color: #3A2975;
        font-size: 20px;
    }
    .dropdown-toggle::after {
        margin-left: 10px;
        position: absolute;
        right: 10px;
        top: 25px
    }
}
