.founders-message .header-image {
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('./images/hongkong.png');
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
}

.founders-message .header-image p {
  color: #DBC7C3;
  font-size: 3rem;
  text-align: center;
}

@media (max-width: 800px) {
  .founders-message .header-image p {
    font-size: 1.8rem;
  }
}

.founders-message .content{
  background: #ebebeb66;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2rem;
  max-width: 1000px;
  margin: auto;
  margin-block: 2rem;
}

.founders-message .content .portrait {
  width: 10rem;
  height: 10rem;
  margin: auto;
}

.founders-message .content .text {
  display: flex;
  flex-direction: column;
  letter-spacing: 0em;
  text-align: left;
  font-size: 1rem;
  line-height: 1.8rem;
}

.founders-message .content .header {
  letter-spacing: 0em;
  /* text-align: center; */
  color: #3A2975;
  font-size: 1.5rem;
  font-weight: bold;
  margin-block: 1rem;
}

.founders-message .content .source {
  text-align: right;
  font-style: italic;
  font-weight: bold;
  margin-bottom: 1rem;
}

.recent-article-container{
  margin: 50px 80px 50px 0px;
  justify-content: space-between;
}