.about-us .content {
    max-width: 1000px;
    margin: auto;
    margin-block: 1rem;
}

.about-us .wide-content {
    max-width: 1800px;
    margin: auto;
    margin-block: 2rem;
}

.content-gray-bg {
    background-color: #ebebeb66;
}

.about-us .header-image {
    background-image: url('./images/mainBg.png');
    height: 300px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
}

.about-us .header-image p{
    color: #DBC7C3;
    font-size: 3rem;
    text-align: center;
}

.about-us .title {
    font-weight: bold;
    font-size: 2rem;
    margin-block: 1rem;
    color: #3A2975;
    text-align: center;
}

.about-us .text-container {
    padding: 2rem;
}

.about-us .secondary-title {
    font-size: 1.8rem;
    color: #760326;
}

.about-us .text {
    font-size: 1.1rem;
    line-height: 1.8rem;
}

.about-us .gray-text {
    color: #6d6e76;
}

.about-us .img-container {
    overflow-x: scroll;
    padding-inline: 1rem;
}

.about-us .img-container img {
    width: 100%;
}

.about-us #what-we-do-card-container {
    display: flex;
    justify-content: space-between;
    padding-inline: 1rem;
    margin-block: 1.5rem;
}

.about-us .what-we-do-card {
    width: 30%;
    box-sizing: border-box;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #2C000D;
    color: white;
    display: flex;
    flex-direction: column;
}

.about-us .what-we-do-card .card-title {
    font-size: 1.5rem;
    padding-top: 0.8rem;
    padding-bottom: 1.2rem;
    text-align: center;
    color: white;
    font-weight: normal;
}

.about-us .what-we-do-card .card-content {
    font-size: 0.9rem;
    line-height: 1.6rem;
    color: white;
}
 
.about-us .what-we-do-card .icon {
    height: 8rem;
    object-fit: contain;
    align-self: center;
}

.about-us #certification-container {
    display: flex;
    justify-content: space-between;
    padding-inline: 1rem;
    margin-block: 1.5rem;
}

.about-us .certification-card {
    width: 30%;
    box-sizing: border-box;
    padding: 1rem;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
}

.about-us .certification-card .card-title {
    font-size: 1.2rem;
    padding-block: 0.5rem;
    padding-inline: 0.3rem;
    text-align: center;
    font-weight: bold;
    position: relative;
    border-top: 3px solid #6A0136;
    border-left: 3px solid #6A0136;
}

.about-us .certification-card .card-title.dashed {
    border-style: dashed;
}

.about-us .certification-card .card-title::after {
    content:'';
    position: absolute;
    right: -5px;
    top: -11.5px;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-left: 15px solid #6A0136;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
}

.about-us .certification-card .card-title.highlight {
    color: #760326;
}

.about-us .certification-card .card-subtitle {
    font-size: 1rem;
    padding-top: 0.8rem;
    padding-bottom: 1.2rem;
    text-align: center;
    font-weight: bold;
}

.about-us .certification-card .card-content {
    font-size: 0.8rem;
    margin-top: 0.5rem;
}

.about-us .certification-card .icon {
    height: 5rem;
    object-fit: contain;
    align-self: center;
}

.about-us .certification-card ul, ol {
    list-style: revert;
}

@media (max-width: 800px) {
    .about-us .what-we-do-card {
        max-width: 80vw;
        height: auto;
    }
    .about-us .what-we-do-card .card-title {
        font-size: 1.2rem;
    }
    .about-us #what-we-do-card-container {
        max-width: 250% !important;
        width: 250%;
        height: auto;
    }
    .about-us #certification-container {
        max-width: 320% !important;
        width: 320%;
        height: auto;
    }
    #what-we-do-img{
        max-width: 250% !important;
        width: 250%;
        height: auto;
    }
    #certification-img{
        max-width: 320% !important;
        width: 320%;
        height: auto;
    }
}