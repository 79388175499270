.become-a-wwi-employer .image{
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('./images/mainBg.png');
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 800px) {
  .become-a-wwi-employer .image{
    height: 200px;
  }
}

.become-a-wwi-employer-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.become-a-wwi-employer .main-heading {
  color: #DBC7C3;
  font-size: 3rem;
  text-align: center;
}

@media (max-width: 800px) {
  .become-a-wwi-employer .main-heading {
    font-size: 1.8rem;
  }
}

.become-a-wwi-employer .content{
  padding: 1rem 2rem;
  max-width: 1000px;
  margin: auto;
  margin-block: 1rem;
  background-color: #ebebeb66;
}

@media (max-width: 800px) {
  .become-a-wwi-employer .content{
    margin: 1rem;
  }
}

.become-a-wwi-employer .title{
  font-size: 1.5rem;
  line-height: 3rem;
  font-weight: 600;
  text-align: left;
  color: #1D3444;
}

.become-a-wwi-employer .become-a-wwi-text-paragraph{
  margin-top: 2rem;
  font-size: 1rem;
  line-height: 1.8rem;
}

.become-a-wwi-employer .input-field{
  margin-top: 0.5rem;
  border: 1px solid #6D6E7680;
  width: 50vw;
  max-width: 100%;
  height: 2rem;
  padding: 1rem;
}

.become-a-wwi-employer .submit-btn{
  margin-top: 0.5rem;
  border: 1px solid #6D6E7680;
  width: 50vw;
  max-width: 100%;
  height: 2.5rem;
  background-color: #760326;
  font-size: 1rem;
  font-weight: 600;
  text-align: center;
  color: white;
}