#root {
  white-space: pre-line;
  height: 100vh;
  overflow: hidden;
}

.App {
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: center;
}

.navColor {
  color: #760326;
  background-color: #d9d9d9
}
:root {
  --global-text-color: #760326;
  --global-text-white: #ffffff;
}

.primary-color-text {
  color: #760326;
  text-decoration-color: #760326;
}

@font-face {
  font-family: "Sofia Light";   /*Can be any text*/
  src: local("sofiapro-light"),
    url("./fonts/sofiapro-light.otf") format("opentype");
}

@font-face {
  font-family: "Sofia Bold";   /*Can be any text*/
  src: local("sofiapro-bold"),
    url("./fonts/sofiapro-bold.ttf") format("truetype");
}

.primary-button {
  border-radius: 0 !important;
  height: 3rem !important;
  width: 10rem !important;
  background: #760326 !important;
  border: none !important;
  color: white !important;
  font-size: 1rem !important;
  font-weight: bold !important;
}