.research-and-methodology {
  width: 100vw;
}

.research-and-methodology .header{
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('./images/mainBg.png');
      display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
}

.research-and-methodology .header p {
  font-size: 3rem;
  color: #DBC7C3;
  text-align: center;
}

@media (max-width: 800px) {
  .research-and-methodology .header p {
    font-size: 1.8rem
  }
}

.research-and-methodology .content{
  max-width: 1000px;
  margin: auto;
  margin-block: 1rem;
  padding: 1rem;
  background-color: #ebebeb66;
}

.research-and-methodology .title-section {
  display: flex;
  justify-content: center;
  margin-block: 1rem;
}

.research-and-methodology .title-section p {
  margin-top: auto;
  margin-bottom: auto;
  font-size: 1.4rem;
  color: #3A2975;
}

.research-and-methodology .title-section p:hover{
  font-weight: bold;
  cursor: pointer;
}

.research-and-methodology .title-section .arrow {
  align-self: center;
  width: 1.5rem;
  height: 1.5rem;
}

.research-and-methodology .description {
  font-size: 1rem;
  text-align: left;
}

.methodology-house {
  width: 90%;
  max-width: 1000px;
  margin: auto;
  margin-top: 2rem;
}

.methodology-pillar-triangle {
  width: 95%;
  height: 5rem;
  position: relative;
  overflow: hidden;
  margin: auto;
}

.methodology-pillar-triangle::before {
  content: "";
  position: absolute;
  width: 100%;
  padding-top: 5rem;
  left: 0;
  top: 0;
  clip-path: polygon(50% 0%, 0 100%, 100% 100%);
  background-color: rgb(0, 0, 55);
}

.methodology-base {
  width: 90%;
  height: 0.5rem;
  margin: auto;
  margin-top: 1rem;
  background-color: rgb(0, 0, 55);
}

.methodology-pillar-row {
  width: 90%;
  margin: auto;
}

.methodology-pillar-container {
  display: flex;
  flex-grow: 1;
  margin-top: 1rem;
}

.methodology-pillar-container .methodology-pillar {
  width: 100%;
  background-color: #760326;
  color: white;
  padding: 1rem 2rem;
  font-size: 1rem;
}

#certification-img {
  width: 100%;
  max-width: 1000px;
  margin: auto;
}

#certification-illustration .column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#certification-illustration .section .title {
  background-color: #AD0303;
  color: white;
  font-size: 1.1rem;
  padding: 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

#certification-illustration .section .text {
  align-self: center;
  margin: auto;
  padding-block: 0.5rem;
}

.methodology-pillar-container .methodology-pillar .title{
  font-size: 1.5rem;
}

.research-and-methodology .pilot-desc{
  text-align: left;
}

.research-and-methodology .pilot-text{
  align-self: center;
  text-align: left;
}

@media (max-width: 800px) {
  .research-and-methodology .pilot-text {
    text-align: center;
  }
}

.research-and-methodology .pilot-container{
  line-height: 1.2;
  width: 100%;
  margin-top: 2rem;
  padding: 0 2rem;
  text-align: center;
}

.research-and-methodology .pilot-container .row {
  margin-block: 1rem;
}

.research-and-methodology .pilot-icons{
  color: #3A2975;
  font-size: 2rem;
}