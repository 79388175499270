.in-the-press-header-bg{
  background: #230E1538;
}

.in-the-press-header{
  height: 200px;
  background:linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url('./images/in-the-press-bg.png');
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  background-repeat: no-repeat;
  opacity: 66%;
  background-size: cover;
}

.in-the-press-heading{
  font-size: 2.5rem;
  width: 100%;
  text-align: center;
  color: #DBC7C3;
  margin: auto;
}

.in-the-press-links-container {
  max-width: 1000px;
  width: 90vw;
  margin: auto;
  margin-block: 2rem;
}

@media only screen and (max-width: 600px) {
  .microlink_card{
    width: 90vw;
    margin-block: 1rem;
  }
}