.supporting-organisations-header{
  height: 170px;
  background: #2C000D;
}

.supporting-organisations-heading {
  color: #DBC7C3;
  font-size: 3rem;
  text-align: center;
}

@media (max-width: 800px) {
  .supporting-organisations-heading {
    font-size: 1.8rem;
  }
}

.organisation-logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.organisation-logo {
  width: 150px;
  height: auto;
  margin: 10px;
}