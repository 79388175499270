.company-scorecard-content {
  margin: 20px;
}

.cert-header-mobile{
  font-size: 19px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0px;
  text-align: left;
  color: #6D6E76;
  text-align: center;
}

.header-container {
  font-family: 'Roboto', sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  color: #760326;
  margin: 32px;
  white-space: nowrap;
}

.search-filter-download-mobile{
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.cert-search-div-mobile{
  display: flex;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 1px solid #97979780;
  border-radius: 5px;
}

.cert-search-mobile{
  height: 48px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  
}
.cert-search-text-mobile{
  font-size: 17px;
  font-style: italic;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.02em;
  text-align: left;
  margin: auto 0;
  color: rgba(133, 129, 129, 0.82);
}
.cert-search-icon-mobile{
  height: 28px;
  margin: auto 5px;
  cursor: pointer;
}

.cert-filter-mobile{
  display: flex;
  margin: 0 10px;
  width: 52px;
  flex-direction: column;
  height: 51px;
  border-radius: 5px;
  background: rgba(118, 3, 38, 1);
  box-shadow: 0px 10px 20px 0px rgba(54, 58, 120, 0.1);
  margin: auto 0px;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(255, 255, 255, 0.94);
  cursor: pointer;

  .span1-mobile{
    position: absolute;
    margin: -4px 10px;
    height: 6px;
  }
  .span2-mobile{
    position: absolute;
    margin: 2px 15px;
    height: 6px;
  }
  .span3-mobile{
    position: absolute;
    margin: 8px 20px;
    height: 6px;
  }
}

.cert-download-mobile{
  height: 46px;
  width: 110px;
  margin: auto 0px;
  border-radius: 20px;
  background: rgba(118, 3, 38, 1);
  box-shadow: 0px 10px 20px 0px rgba(54, 58, 120, 0.1);
  text-align: center;
  cursor: pointer;

}
.span4-mobile{
  font-size: 15px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: rgba(255, 255, 255, 1);
  margin: 8px 20px;
}

.company-details-table-container-mobile{
  margin: 20px;
  overflow-x: auto;
  max-width: 340px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.company-details-table-mobile{
  color: #7F7F7F;
  width: 100%;
  border: 2px solid #BFBFBF;
  border-collapse: collapse;
  font-size: 0.6rem;
}
.tr-mobile{
  text-align: center;
}
.thead-mobile {
  position: sticky;
  top: 0;
}
.th-mobile {
  position: sticky;
  top: 0;
  border: 2px solid #BFBFBF;
  padding: 18px;
}
.td-mobile {
  border: 2px solid #BFBFBF;
  color: #5f124f;
  font-weight: bold;
  padding: 12px 0;
}

.pagination-mobile {
  font-size: 17px;
  font-style: italic;
  font-weight: 700;
  letter-spacing: 0px;
  text-align: left;
  color: #656568;
  margin-top: 20px;
  margin-left: 5px;
  max-width: 100%;
  overflow-x: scroll;
}

.blur-effect {
  filter: blur(1.2px);
}

.scorecard-modal-mobile{
  height: 100vh;
  z-index: 9999;
  /* background: #6D6D6DCC; */
}

.scorecard-submodal-mobile{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
} 

.scorecard-popup-mobile{
  background: #430115;
  width: 307px;
  height: 142px;
  border-radius: 2px;
}

.scorecard-popup-text-mobile{
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-align: left;
  color: white;
  margin: 20px;
}