*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  font-size: 100%;
}

.footer {
  display: flex;
  flex-flow: row wrap;
  padding: 30px 30px 40px 30px;
  margin: 0 5px 0 0;
  color: #2f2f2f;
  background-color: #2C000D;
  border-top: 1px solid #e5e5e5;
}

.footer-heading-text {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 28px;
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #FFFFFF;
}

.footer a {
  color: #ffffffea !important;

}

.footer h2 {
  margin: 0;
  color: white;
  font-size: 1.1rem !important;
}

.footer li {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 19px;
  line-height: 200%;
  margin-top: 20px;
  /* identical to box height, or 38px */

  text-align: left;

  color: #FFFFFF;
}

.footer li:hover {
  color: white;
}

.footer>* {
  flex: 1 100%;
}

.footer__addr {
  margin-right: 1.25em;
}

.footer__logo {
  font-family: 'Pacifico', cursive;
  font-weight: 400;
  text-transform: lowercase;
  font-size: 1.5rem;
  padding-left: 10px;
}

.footer__addr h2 {
  margin-top: 1.3em;
  font-size: 15px;
  font-weight: 400;
}

.nav__title {
  font-weight: 400;
  font-size: 15px;
}

.footer address {
  font-style: normal;
  color: #999;
}

.footer__btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  max-width: max-content;
  background-color: rgb(33, 33, 33, 0.07);
  border-radius: 100px;
  color: #2f2f2f;
  line-height: 0;
  margin: 0.6em 0;
  font-size: 1rem;
  padding: 0 1.3em;
}

.footer ul {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
}

.footer li {
  line-height: 2em;
}

.footer a {
  text-decoration: none;
}

.footer__nav {
  display: flex;
  flex-flow: row wrap;
}

.questionnaire {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  background:rgb(55, 13, 25);
  height: 50%;
  max-width: 1320px;
  margin: auto;
  margin-bottom: 20px;
}

.survey-text {
  position: relative;
  font-family: 'Sen', Arial, sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 118px;
  margin-left: 125px;
  /* or 133% */
  letter-spacing: -2px;
  bottom: 7px;

  color: rgba(219, 199, 195, 1);
}

.questionnaire-button{
  padding: 1rem 3rem;
  background: white;
  font-style: normal;
  font-weight: 700;
  font-size: 1.2rem;
  color: #3A2975;
}

.footer__nav>* {
  flex: 1 50%;
  margin-right: 1.25em;
}

.nav__ul a {
  color: #999;
}

.nav__ul--extra {
  column-count: 2;
  column-gap: 1.25em;
}

.legal {
  display: flex;
  flex-wrap: wrap;
  color: #999;
}

.legal__links {
  display: flex;
  align-items: center;
}

.heart {
  color: #2f2f2f;
}

hr.line {
  border: 1px solid white;
  margin: 0px 45px
}
.footer__bottom{
  display: flex;
  flex-direction: row;
  width: 100%;
  color: white;
  justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 20px;
}

.footer__bottom p{
margin: 0 1%;
color: rgba(219, 199, 195, 1);

}

.footer__bottom_right{
  display:flex;
  flex-direction: row;
}

.footer__bottom_right p{
  margin-right: 20px;
}
.socials{
  margin-right: 5%;
  display: flex;
  flex-direction: row;
}
.socials img{
  height: 2rem;
  width: 2rem
}

.socials a{
  filter: brightness(100%) !important;

}


@media screen and (min-width: 24.375em) {
  .legal .legal__links {
    margin-left: auto;
  }
}

@media screen and (min-width: 600px) {
  .footer__nav>* {
    flex: 1;
  }

  .nav__item--extra {
    flex-grow: 2;
  }

  .footer__addr {
    flex: 1 0px;
  }

  .footer__nav {
    flex: 2 0px;
  }
}

@media only screen and (max-width: 600px) {
  .footer body {
    font-size: 0.6rem !important;
  }
  .footer hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(255, 255, 255, 1);
    width: 100%;
  }

  .footer>.container {
    flex-direction: column-reverse;
    align-items: flex-start;
  }

  .footer-logo {
    height: 4rem;
  }

  .footer__logo{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .footer__addr{
    width: 100%;
  }

  .socials{
    display: flex;
    flex-direction: row;
  }

  .socials img{
    height: 2.5rem;
    width: 2.5rem
  }

  .socials a{
    filter: brightness(100%) !important;

  }
  
  .footer h2 {
    margin-top: 20px;
    margin-left: 0.5px !important;
    font-size: 1.25rem;
    color: white;
  }

  .footer li{
    margin-left: 0.25rem !important;
    font-size: 1.25rem;
  }

  .footer a {
    font-size: 1.25rem;
    margin-bottom: 15px;
    line-height: 30px;
    filter: brightness(80%);
  }

  .tell-us-more{
    padding: 1rem 0.5rem;
    flex-shrink: 0;
    background: rgb(55, 13, 25);
    text-align: center;
  }

  .tell-us-more-text{
    margin-top: 20px;
    color: #DBC7C3;
    text-align: center;
    font-size: 30px;
    font-family: 'Sen', Arial, sans-serif;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -2px;
  }
  
  .questionnaire-button-mobile{
    padding: 1rem 3rem;
    background: white;
    font-style: normal;
    font-weight: 700;
    font-size: 1.2rem;
    color: #3A2975;
  }

  .privacy-statement{
    color: #FFF;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    line-height: 40px;
    margin-left: 15px;
    margin-top: 20px;
  }
}